import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './i18n';
import './index.scss';
import App from './components/app';

window.AvailableLocales = ['nl', 'en'];

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://b47b02e9fb9a4865abf8690a6cb6ed06@o248658.ingest.sentry.io/1765431',
    release: 'manuals@' + process.env.npm_package_version,
  });
}

createRoot(document.getElementById('root')).render(<App />);
